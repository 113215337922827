import React, { useEffect, useState } from "react"
import Seo from "../core/Seo"
import { Content, Section } from "../core/LayoutComponents"
const BtapPage = () => {
  const [iframeHeight, setIframeHeight] = useState(3500)

  // TODO: dynamically set the iframe height for form
  // useEffect(() => {
  //   // get the iframe content height
  //   const iframe = document.querySelector("iframe[name=btap_zoho_iframe]")
  //   const iframeContent = iframe.contentWindow.document.body.scrollHeight
  //   setIframeHeight(iframeContent)
  // }, [])

  return (
    <Section>
      <Seo title="We Sparkle • BTAP 2023" includeZohoTracking />
      <Content>
        <h1>Minneapolis B-TAP</h1>
      </Content>

      <iframe
        frameBorder="0"
        allowtransparency="true"
        style={{
          height: iframeHeight + "px",
          width: "100%",
          border: "none",
        }}
        src="https://forms.zohopublic.com/wesparkleco/form/BTAPWaitingList/formperma/71wyAsqYQuaNraj4KT5wPLAh9c_EF0Ucne9_BmAGRHA"
        name="btap_zoho_iframe"
      ></iframe>
    </Section>
  )
}

export default BtapPage
